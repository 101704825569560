<template>
  <b-card>
    <FormView
      :data="$store.getters['wallet/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'balance',
          label: 'Balance',
          type: 'gold',
        },
        {
          key: 'updatedAt',
          label: 'Last Updated',
          type: 'datetime',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('wallet/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
